import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"


const Header = ({ siteTitle }) => (
  <div>
    <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl"><Link to="/">{siteTitle}</Link></h2>
    <div className="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:gap-5 lg:items-center">
      <p className="text-xl text-gray-500">//TODO: insert tagline...</p>
    </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
